<template>
    <div class="root">
        <div class="translate-box">
            <p>{{ currentQuestion }}</p>
            <div v-if="!showAnswer">
    <p class="correctAnswer" :class="{ 'blurry': isBlurry }">{{ currentAnswer }}</p>
</div>
<div v-if="showAnswer">
    <p class="correctAnswer">{{ currentAnswer }}</p>
</div>
            <input type="text" v-model="userInput" @input="checkAnswer" :class="{ 'answer-correct': answerIsCorrect }">
            <div class="lightbulb-container">
                <img :src="lightbulb" alt="Hint" @click="toggleLightbulb"/>
                <p>{{ lightbulbText }}</p>
            </div>
        </div>
        <LevelCompleted v-if="allQuestionsExhausted" :isVisible="allQuestionsExhausted" @continueFinished="reset"/>
    </div>
</template>

    
    
    
    <script>
    
    import lightbulb from '@/assets/lightbulb.png';
    import LevelCompleted from '@/components/LevelCompleted.vue'; 
    
    export default {
        props: {
    minLevel: {
      type: Number,
      required: true
    },
    maxLevel: {
      type: Number,
      required: true
    }
  },
      components: {
        LevelCompleted
      },

    
      data() {
        return {
            showAnswer: false,
            isBlurry: true,
            questionNumber: null,
            currentQuestionNumber: null,
            currentLevel: null,
          allQuestionsExhausted: false,
            hints:[],
            clueMap: {
        'N': "Noun",
        '?': "Question/exclamation sign",
        'F': "Question word",
        'D': "Adverb",
        'E': "Preposition",
        'Q': "Quantifier",
        'V': "Verb",
        'P': "Possessive",
        '!': "Infinitive",
        'O': "Verb (present indicative)",
        'T': "Article",
        'B': "Number",
        'J': "Adjective",
        'M': "Impersonal se",
        'X': "Verb (aux. present. ind.)",
        'S': "Demonstrative",
        'R': "Personal pronoun",
        'Y': "Conjunction (coord.)",
        'U': "Relative pronoun/conjunction",
        'G': "Verb (past. ind.)",
        'W': "Verb (present subjunctive)",
        'L': "Interjection (ay, hola, ojalá...)",
        'H': "Conjunction (time)",
        'K': "Verb (imperative)",
        ',': "Coma",
        'I': "Verb (imperfect, subjunctive)",
        'C': "Ordinal number",
        '1': "Verb (future, indicative)",
        '2': "Verb (conditional, indicative)",
        '3': "Conjunction (conditional)",
        '4': "Verb (gerund)",
        '5': "Verb (infinitive) + I.O.Pronoun",
        '6': "Verb (participle)",
        '7': "Indefinite pronoun",
        '8': "Verb (aux., conditional, indicative)",
        '9': "Verb (aux., future, indicative)",
        '0': "Reflexive se",
        '+': "Verb (aux., imperfect, indicative)",
        '€': "Verb (imperfect, indicative)",
        '%': "Comparative",
        '$': "Verb (reflexive)",
        '@': "Conjunction (location)",
        '#': "Indefinite pronoun",
        ':': "Conjunction (conditional)"
    },
    
            lightbulb,
                        lightbulbVisible: false,
                lightbulbText: " ",
          questions: [],
          parsedHints: [],
          answers: [],
          questionIndex: 0,
          userInput: "",
              answerIsCorrect: false
    
        };
      },
    watch: {
      currentQuestion(newQuestion, oldQuestion) {
        if (newQuestion !== oldQuestion) {
          this.userInput = "";
                      this.lightbulbVisible = false;
                this.lightbulbText = " "; 
        }
      }
    },
    
      computed: {
        currentQuestion() {
          return this.questions[this.questionIndex];
        },
        currentAnswer() {
          return this.answers[this.questionIndex];
        }
      },
    
      methods: {
        reset() {
          this.allQuestionsExhausted = false;
          this.$emit('allQuestionsExhausted');
        },
        toggleLightbulb() {
    this.lightbulbVisible = !this.lightbulbVisible;
    this.lightbulbText = this.lightbulbVisible ? this.parsedHints.join(', ') : " "; // Display the parsed hints
  },

        async fetchQuestionsAndAnswers() {
            this.showAnswer = false;
            this.isBlurry = true;
        console.log('Fetching questions and answers...');

        try {
            this.currentLevel = Math.floor(Math.random() * (this.maxLevel - this.minLevel + 1)) + this.minLevel;  // Adjusted this line
            console.log('Current Level:', this.currentLevel); // Logging the current level
            
            const questionResponse = await fetch(`./TranslateExercisesTxt/TranslateQuestionsLevel${this.currentLevel}.txt`);
            const answerResponse = await fetch(`./TranslateExercisesTxt/TranslateAnswersLevel${this.currentLevel}.txt`);

            const questionsDataRaw = await questionResponse.text();
            const answersData = await answerResponse.text();

            const questions = questionsDataRaw.split("\n").filter(q => q.trim());
            const answers = answersData.split("\n").filter(a => a.trim());

    // Extract the clue characters from the question
    const randomIndex = Math.floor(Math.random() * questions.length);
    const rawQuestion = questions[randomIndex].trim();
    const clueChars = rawQuestion.split('*')[1]; // Get the part after '*'
    const actualQuestion = rawQuestion.split('*')[0]; // Get the part before '*'
            this.questionNumber = randomIndex + 1;  // Store the question number

            console.log('Question Number:', this.questionNumber); // Logging the question number
            
    // Convert clue characters into an array of hints using clueMap
    this.parsedHints = clueChars ? clueChars.split('').map(c => this.clueMap[c]) : [];

    this.questions = [actualQuestion]; // Use the actual question part (without clues)
    this.answers = [answers[randomIndex].trim()];

        } catch (error) {
            console.log('Error:', error);
        }
        
  this.isBlurry = true; // Reset the blurry state when a new question is loaded

setTimeout(() => {
  this.isBlurry = false; // Remove the blur after 30 seconds
}, 1000); // 30000ms equals 30 seconds
    },
    
    
    checkAnswer() {
      const normalizedInput = this.userInput.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const normalizedAnswer = this.currentAnswer.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
      if (normalizedInput === normalizedAnswer) {
        this.answerIsCorrect = true;
        this.playAudio(this.questionIndex);
        this.isBlurry = false;
      }
    },
    
    playAudio() {
        console.log('Playing Audio for Question Number:', this.questionNumber); // Log before playing the audio
        this.showAnswer = true;
        const audioPath = `./TranslateExercisisAudios/Level${this.currentLevel}/${this.questionNumber}.mp3`;  
        console.log('Audio Path:', audioPath); // Log the audio path

        const audio = new Audio(audioPath);
        audio.addEventListener('ended', () => {
            setTimeout(() => {
                const randomNumber = Math.floor(Math.random() * 3) + 1;
                switch (randomNumber) {
                    case 1:
                        this.$emit('startPractiseTheory');
                        break;
                    case 2:
                        this.$emit('startPractiseVocab');
                        break;
                    case 3:
                        this.fetchQuestionsAndAnswers();
                        break;
                    default:
                        console.log('Unknown case');
                }
            }, 2000);
        });

        audio.play();
    },
      moveToNextQuestion() {
        this.questionIndex++;
        if (this.questionIndex >= this.questions.length) {
          this.allQuestionsExhausted = true;
        } else {
          this.answerIsCorrect = false;
        }
      },
      },
    created() {
      console.log("TranslateExercise created");
          this.fetchQuestionsAndAnswers();
    
    },
    mounted() {
      console.log("TranslateExercise mounted");
    },
    beforeUnmount() {
      console.log("TranslateExercise about to be destroyed");
    }
    }
    </script>
    
    <style scoped>
    .root {
        padding-top: 50px;
    }
    .lightbulb-container {
        display: flex;
        align-items: center;  
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        padding-top: 10px;
    }
    
    .lightbulb-container img{
        cursor: pointer;  
        margin-right: 10px;  
        width: 30px;
        height: 30px;
    }
    
    .lightbulb-container p{
        padding-top: 10px;
    }
    
    
    input[type="text"] {
      border: 1px solid #aa3f3f;
      border-color: var(--input-border-color);
    }
    .correctAnswer {
    color: #5eb8ece8;
    transition: filter 30s linear, opacity 30s linear; /* Add a transition effect */
}

.blurry {
    filter: blur(20px);
    opacity: 1;
}
@keyframes revealAnswer {
    0% {
        filter: blur(20px);
        opacity: 0.5;
    }
    100% {
        filter: blur(0px);
        opacity: 1;
    }
}
    .input.answer-correct {
    background-color: green;
    }
    
    .translate-box {
        padding: 20px;
        background-color: #3333334d;
        border-radius: 8px;
        box-sizing: border-box;
        max-width: 80%;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
        margin-left: auto;
        margin-right: auto;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color: #e6e6e6;
        line-height: 1.5;
        font-size: 20px;
        overflow: hidden;
    }
    
    input[type="text"] {
        padding: 10px;
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid #58575775;
        width: 100%;
        box-sizing: border-box;
        margin-top: 10px;
        background-color: #fcfcfc83;
    }
    </style>
    
    