<template>
  <div>
    <NavigationBar @logoClicked="showLevelList" @showForumComponent="Forumclicked"/>

    <LevelListVue 
      v-if="!showGlossary && !showDictation && !showPractiseTranslate && !showPractiseVocab && !showPractiseTheory && !showGrammar && !showTheoryBox && !showVocabExercise && !showTheoryExercise && !showTranslateExercise" 
      @levelSelected="handleLevelSelected" 
      @dictationSelected="dictationSelected"
      @grammarClicked="showGrammarComponent"
      @glossaryClicked="toggleGlossary"
      @practiseClicked="togglePractisePop"
    />
    <div>

  </div>
    <GlossaryList v-if="showGlossary" /> <!-- Render GlossaryList here -->
    <PractisePop v-if="showPractisePop"
     @startPractise="startPractise"  
     :visible="showPractisePop" 
     @popupClosed="showPractisePop = false" />

    <GrammarList v-if="showGrammar" />
  <TheoryBox v-if="showTheoryBox" :level="selectedLevel" @continue="showTheoryExerciseComponent" />



  <TheoryExercise v-if="showTheoryExercise" :level="selectedLevel" @questionsExhausted="handleQuestionsExhausted" />

<VocabExercise v-if="showVocabExercise" :level="selectedLevel" @questionsVocabExhausted="handleVocabQuestionsExhausted" />
<TranslateExercise v-if="showTranslateExercise" :level="selectedLevel" @allQuestionsExhausted="showLevelList"/>
<PractiseTheory 
    v-if="showPractiseTheory" 
    @startPractiseVocab="startPractiseVocab"
    @startPractiseTranslate="startPractiseTranslate" 
    :minLevel="minLevel" 
    :maxLevel="maxLevel"
/>
<PractiseVocab
    v-if="showPractiseVocab"
     @startPractiseTheory="startPractiseTheory" 
     @startPractiseTranslate="startPractiseTranslate"
     :minLevel="minLevel" 
    :maxLevel="maxLevel"/>
    <PractiseTranslate
    v-if="showPractiseTranslate" @startPractiseVocab="startPractiseVocab"  @startPractiseTheory="startPractiseTheory" :minLevel="minLevel" :maxLevel="maxLevel"/>
    <DictationComponent v-if="showDictation" @allQuestionsExhausted="showLevelList" :level="selectedDictationLevel"></DictationComponent>
  <ForumComponent v-if="showForumComponent" @CloseForum="CloseForum"></ForumComponent>

  <div><br><br><br></div>

  <div class="social-footer" v-if="!showGlossary && !showDictation && !showPractiseTranslate && !showPractiseVocab && !showPractiseTheory && !showGrammar && !showTheoryBox && !showVocabExercise && !showTheoryExercise && !showTranslateExercise">
  <span>Share!</span>
  <a href="#" @click="openFacebook"><i class="fab fa-facebook-f"></i></a>
  <a href="#" @click="openTwitter"><i class="fab fa-twitter"></i></a>
  <a href="#" @click="openLinkedIn"><i class="fab fa-linkedin-in"></i></a>
 
  <span><a href="http://www.labelspanish.com/privacy.html" target="_blank">Privacy Policy</a></span>
 
</div>
  </div>
</template>



<script>
import ForumComponent from './components/ForumComponent.vue';  // Import GlossaryList component
import GlossaryList from './components/GlossaryList.vue';  // Import GlossaryList component
import PractisePop from './components/PractisePop.vue';  // Import GlossaryList component
import NavigationBar from '@/components/NavigationBar.vue'; // Adjust the path based on your folder structure
import LevelListVue from './components/LevelList.vue';
import TheoryBox from './components/TheoryBox.vue';
import TheoryExercise from './components/TheoryExercise.vue'; // Include this when you've created it
import VocabExercise from './components/VocabExercise.vue'; // adjust the path based on your project structure
import TranslateExercise from './components/TranslateExercise.vue'; // adjust the path based on your project structure
import GrammarList from './components/GrammarList.vue'; // Adjust the path according to your folder structure
import PractiseTheory from './components/PractiseTheory.vue';
import PractiseVocab from './components/PractiseVocab.vue';
import PractiseTranslate from './components/PractiseTranslate.vue';
import DictationComponent from './components/DictationComponent.vue';

export default {
  name: 'App',
  components: {
    DictationComponent,
    ForumComponent,
    PractiseVocab,
    PractiseTranslate,
    PractiseTheory,
    PractisePop,
    GlossaryList,
    GrammarList,
    NavigationBar,
    LevelListVue,
    TheoryBox,
    TheoryExercise,
    VocabExercise,
    TranslateExercise,
},

  data () {
    return {
      selectedDictationLevel: null, // add this line
      showDictation: false,
      minLevel: 1,
      maxLevel: 54,
      showForumComponent: false,
      showPractiseTheory: false,
      showPractiseTranslate: false,
      isPractising: true,
      showPractiseExercise: false, 
      showPractisePop: false,
      showGlossary: false,
      showGrammar: false,
      selectedLevel: null,
      showTheoryBox: false,
      showTheoryExercise: false,
      showVocabExercise: false,
      currentLevel: null,
      showTranslateExercise: false,
      showPractiseVocab: false,
    }
  },
  methods: {

    dictationSelected(levelNumber){
      this.selectedDictationLevel = levelNumber; // store selected level number
    this.showDictation = true;
          this.showForumComponent = false;
        this.showGlossary = false;
        this.showGrammar = false;
        this.showTheoryBox = false;
        this.showTheoryExercise = false;
        this.showVocabExercise = false;
        this.showTranslateExercise = false;
        this.showPractisePop = false;
        // show PractiseTheory component
        this.showPractiseTheory = false;
        this.showPractiseVocab = false;
        this.showPractiseTranslate = false;
    },
    CloseForum(){
      this.showForumComponent = false
    },
    Forumclicked(){
      this.showForumComponent = true
    },
    startPractiseTranslate() {
        console.log('Starting translate Practice in appvue...');
        // hide all other components
        this.showForumComponent = false;
        this.showGlossary = false;
        this.showGrammar = false;
        this.showTheoryBox = false;
        this.showTheoryExercise = false;
        this.showVocabExercise = false;
        this.showTranslateExercise = false;
        this.showPractisePop = false;
        // show PractiseTheory component
        this.showPractiseTheory = false;
        this.showPractiseVocab = false;
        this.showPractiseTranslate = true;
        // Your logic here
    },
    startPractiseVocab() {
      this.showForumComponent = false;

        console.log('Starting Vocabulary Practice in appvue...');
        // hide all other components
        this.showGlossary = false;
        this.showGrammar = false;
        this.showTheoryBox = false;
        this.showTheoryExercise = false;
        this.showVocabExercise = false;
        this.showTranslateExercise = false;
        this.showPractisePop = false;
        // show PractiseTheory component
        this.showPractiseTheory = false;
        this.showPractiseVocab = true;
        this.showPractiseTranslate = false;

        // Your logic here
    },
    startPractiseTheory() {
      this.showForumComponent = false;

        console.log('Starting theory Practice in appvue...');
        // hide all other components
        this.showGlossary = false;
        this.showGrammar = false;
        this.showTheoryBox = false;
        this.showTheoryExercise = false;
        this.showVocabExercise = false;
        this.showTranslateExercise = false;
        this.showPractisePop = false;
        // show PractiseTheory component
        this.showPractiseTheory = true;
        this.showPractiseVocab = false;
        this.showPractiseTranslate = false;

        // Your logic here
    },

    startPractise({ minLevel, maxLevel }) {
      this.showForumComponent = false;

  console.log('startpractiseinAPPVue');
  this.minLevel = minLevel;  // set minLevel from payload
  this.maxLevel = maxLevel; 
    this.showGlossary = false;
    this.showGrammar = false;
    this.showTheoryBox = false;
    this.showTheoryExercise = false;
    this.showVocabExercise = false;
    this.showTranslateExercise = false;
    this.showPractisePop = false;
    // show PractiseTheory component
    this.showPractiseTheory = true;
  },

  
    togglePractisePop(){
      this.showForumComponent = false;

      this.showPractisePop = !this.showPractisePop;
      console.log(this.showPractisePop)
    },

    toggleGlossary() {
      this.showForumComponent = false;

      this.showGlossary = !this.showGlossary;  // Toggle showGlossary when the glossary icon is clicked
      if (this.showGlossary) {
        this.showGrammar = false;
        this.showTheoryBox = false;
    this.showTheoryExercise = false;
    this.showVocabExercise = false;
    this.showTranslateExercise = false;
        }
    },
    showGrammarComponent() {
      this.showForumComponent = false;

    this.showGrammar = true;
    this.showTheoryBox = false;
    this.showTheoryExercise = false;
    this.showVocabExercise = false;
    this.showTranslateExercise = false;
  },
        handleVocabQuestionsExhausted() {
          
    this.showVocabExercise = false;
    this.showTheoryBox = false;
    this.showTheoryExercise = false;
    this.showTranslateExercise = true;
    },
handleQuestionsExhausted() {
    console.log("Handling questionsExhausted event");
    this.showTheoryExercise = false;
    this.showVocabExercise = true;
},


handleLevelSelected(index) {
    this.selectedLevel = index + 1;  // Add 1 if you want 1-based levels, otherwise use it as it is
    this.showTheoryBox = true;
},

showLevelList() {
  console.log("levellsit")
  this.showForumComponent = false;
this.showDictation = false;
  this.showGlossary = false,
  this.showGrammar = false,
    this.showTheoryBox = false;
    this.showTheoryExercise = false;
    this.showVocabExercise = false;
    this.showTranslateExercise = false;
    this.showPractiseTheory = false,
    this.showPractiseTranslate = false,
    this.showPractiseVocab = false
},
openFacebook() {
    const url = encodeURIComponent('https://www.labelspanish.com');
    const text = encodeURIComponent('Check out this amazing webpage to learn Spanish!');
    window.open(`https://www.facebook.com/sharer.php?u=${url}&quote=${text}`, '_blank');
  },

  openTwitter() {
    const text = encodeURIComponent('Check out this amazing webpage to learn Spanish!') + ' ' + encodeURIComponent('https://www.labelspanish.com');
    window.open(`https://twitter.com/intent/tweet?text=${text}`, '_blank');
  },

  openLinkedIn() {
    const url = encodeURIComponent('https://www.labelspanish.com');
    const title = encodeURIComponent('Amazing Webpage');
    const summary = encodeURIComponent('Check out this amazing to learn Spanish!');
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}&summary=${summary}`, '_blank');
  },
    showTheoryExerciseComponent() {
      this.showForumComponent = false;

      this.showTheoryBox = false;
      this.showTheoryExercise = true;
    }
  
},
  watch: {
    showTheoryExercise: {
      handler(newValue) {
        if (newValue) { 
          document.body.style.marginTop = '0px';
          document.body.style.overflow = 'hidden'; 
        } else {
          document.body.style.overflow = ''; 
        }
      },
      immediate: true
    },

    showVocabExercise: {
        handler(newValue) {
            if (newValue) { 
                document.body.style.marginTop = '0px';
                document.body.style.overflow = 'hidden'; 
            } else {
                document.body.style.overflow = ''; 
            }
        },
        immediate: true
    }
  }

}

</script>

<style>
#app {
  overflow: hidden;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;

font-family: 'Roboto', sans-serif;  /* Set the font to Roboto */

}
body {
  margin: 0;
  height: 80vh;
  position: relative;
}
body::before {
  content: '';
  position: fixed; 
  top: -50px;
  right: -50px;
  bottom: -50px;
  left: -50px;
  z-index: -1;

  background: linear-gradient(
  45deg,
  #2a2f6b,      /* Deep blue at the top */
  #735d9c 50%,  /* Intermediate violet/purple */
  #d66e88 100%  /* Pinkish tone at the bottom */
);

}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

a {
    color: #5DA1C9;
}

/* Optional: Add this if you want to change the color when the hyperlink is hovered */
a:hover {
    color: #4dbdf1; /* You can choose another color for the hover effect */
}

/* Add the styles for the scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #748799, #9cadd1);
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, #909bb8, #b2d1ec);
}

::-webkit-scrollbar-track {
    background: #393c46;
    border-radius: 5px;
}
.bottom-text {
  color: #ffffff;

  margin-top: auto;
  text-align: center;
  font-size: smaller;
}

.bottom-text a {
  color: #5DA1C9;
  font-size: smaller;
}

.bottom-text a:hover {
  color: #4dbdf1;
}

.social-footer {
  position: fixed;
  bottom: 0;
  
  width: 100%;
  background: rgba(0, 0, 0, 0.342); /* This will make your footer background semi-transparent */
  padding: 10px;
  text-align: center;
}

.social-footer a {
  color: white;
  margin: 0 10px;
  transition: color 0.3s;
}

.social-footer a:hover {
  color: #5DA1C9; 
}
.social-footer span {
  margin-right: 20px;
  color: white; /* Adjust color as per your design */
}

/* Adjust the size of the icons if necessary */
.social-footer i {
  font-size: 15px;
}
</style>
