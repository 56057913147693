<template>
    <div class="overlay" @click.self="outsideClick">
        <div class="discussion-container">
            <div class="header">
                <button 
    class="discussion-button" 
    :class="{ active: !showGrammarTables }" 
    @click="showDiscussion">
    Discussion
</button>
<button 
    class="grammar-button" 
    :class="{ active: showGrammarTables }" 
    @click="showGrammar">
    Grammar
</button>

    <button class="close-button" @click="closeDiscussion">X</button>
</div>

<div class="discussion-content">
    <LevelListForum @show-forum-messages="handleForumMessages" v-if="!showForumMessages && !showGrammarTables"></LevelListForum>
    <ForumMessages v-if="showForumMessages" :level-index="levelIndex" :level-name="levelName"></ForumMessages>
    <GrammarListPopUp v-if="showGrammarTables"></GrammarListPopUp> <!-- Add this line to include the GrammarList component -->
</div>
<div class="sendMail-container" v-if="showForumMessages">
    <p class="sendMail">Send your questions to <a href="mailto:labelspanishapp@gmail.com">labelspanishapp@gmail.com</a></p>
</div>

        </div>
    </div>
</template>

<script>
import LevelListForum from './LevelListForum.vue';
import ForumMessages from './ForumMessages.vue';
import GrammarListPopUp from './GrammarListPopUp.vue'; // Import the GrammarList component

export default {
    data() {
        return {
            showInputMessage: false,
            showForumMessages: false,
            showGrammarTables: false, // Add this line to control the visibility of GrammarList
            levelIndex: null,
            levelName: '',
        };
    },
    components: { 
        GrammarListPopUp,
        LevelListForum,
        ForumMessages,
    },
    methods: {
        outsideClick(e) {
            if (!this.$el.querySelector('.discussion-container').contains(e.target)) {
                console.log('Clicked outside');
                this.closeDiscussion();
            }
        },
        showDiscussion() {
        this.showGrammarTables = false;
        this.showForumMessages = false;
    },
    showGrammar() {
        this.showGrammarTables = true;
        this.showForumMessages = false;
    },
        closeDiscussion() {
            console.log('Close button clicked');
            this.$emit('CloseForum');
        },
        handleForumMessages(payload) {
            console.log('Item clicked:', payload.index, payload.levelName);
            this.levelIndex = payload.index;
            this.levelName = payload.levelName;
            this.showForumMessages = true;
            this.showInputMessage = true;
        },
    }
}
</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(63, 63, 63, 0.753);
    backdrop-filter: blur(5px); /* This will make the background behind the overlay blurry */
    z-index: 1000;
}
.header {
    display: flex;
    justify-content: space-between; /* Changed from justify-content:left; */
    align-items: center;
    width: 100%; /* Ensure the header takes up the full width of its container */
}
.sendMail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.sendMail {
    color:#e9e9e9;

    
}
    h2 {
        margin: 20px;
        color: #e9e9e9;
    }

    .close-button {
        margin-left: auto;

        background-color: transparent;
        border: none;
        font-size: 1.5em;
        cursor: pointer;
        color: #e9e9e9;
        margin-top: 10px;
        
    }
.discussion-container {
    position: relative;

    width: 85%;
    height: 70%;
    font-size: 0.8em;
    padding: 10px;
    opacity: 0.9;
    border-radius: 5px;
    background-color:rgba(50, 50, 50, 0.815);
    overflow: auto; /* Add scroll if content overflows */
    display: flex;
    flex-direction: column; /* Makes children stack vertically */
}
.discussion-button, .grammar-button {
    background-color: #8080804d;
    border: none;
    border-radius: 5px; /* Adjust as needed for rounded corners */
    font-size: 1.3em;
    cursor: pointer;
    color: #e9e9e9;
    margin-left: 10px; /* This will give 20px spacing from the left */
    margin-top: 10px;
    padding: 10px 10px; /* Add padding to make the buttons look better */
    transition: background-color 0.3s ease-in-out; /* Add transition for smooth background color change */
}

.discussion-button:hover, .grammar-button:hover {
    background-color: #5da1c97e;
}

.discussion-content {
    margin-top: 20px;

    flex-grow: 1; /* Allows this element to take up all available space */
    overflow-y: auto; /* Make this scrollable */
    margin-bottom: 10px; /* Add space between content and input box */
}

.discussion-button.active, .grammar-button.active {
    background-color: #4d99b3; /* Adjust this color to make it a bit lighter than hover state */
}


</style>
