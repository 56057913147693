<template>
  <div class="theory-box-root">

  <div class="theory-box">
    <transition name="fade" @after-leave="afterLeave">
      <div v-if="showQuestion && currentQuestion">
        <p>{{ currentQuestion.question }}</p>
<div
  v-for="item in filteredShuffledAnswers"
  :key="item.index"
  :class="buttonClass(item.index)"
  @click="selectAnswer(item.answer)"
>
  {{ item.answer }}
</div>


      </div>
    </transition>
  </div>
  </div>
</template>

<script>
import { useGtag } from "vue-gtag-next";

export default {
  data() {
    return {
      questions: [],
      questionIndex: 0,
      selectedAnswer: null,
      showQuestion: true,
    };
  },
  props: {
  level: {
    type: Number,
    required: true
  }
},

  computed: {
filteredShuffledAnswers() {
  return this.shuffledAnswers
    .map((answer, index) => ({ answer, index })) // Map to include original index
    .filter(item => item.answer && item.answer.trim() !== '');
},


    currentQuestion() {
      return this.questions[this.questionIndex];
    },
    shuffledAnswers() {
      if (!this.currentQuestion) return [];
      let answers = [...this.currentQuestion.answers];
      for (let i = answers.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [answers[i], answers[j]] = [answers[j], answers[i]];
      }
      return answers;
    },
  },
  methods: {
afterLeave() {
  
  this.questionIndex++;
  this.selectedAnswer = null;
if (this.questionIndex < this.questions.length) {
    this.showQuestion = true;
} else {
    console.log("Emitting questionsExhausted event");
    this.$emit('questionsExhausted');
}

},


async fetchQuestions() {
  const response = await fetch(`/TheoryExercisesTxt/TheoryExercise${this.level}.txt`);
  const data = await response.text();
  const lines = data.split("\n");
  for (let i = 0; i < lines.length; i += 5) {
    this.questions.push({
      question: lines[i],
      answers: [lines[i + 1], lines[i + 2], lines[i + 3], lines[i + 4]],
      correctAnswer: lines[i + 1],
    });
  }
},

  
buttonClass(index) {
  const answer = this.shuffledAnswers[index];

  if (this.selectedAnswer === this.currentQuestion.correctAnswer && answer === this.currentQuestion.correctAnswer) {
    return 'correct';
  }
  
  if (answer === this.selectedAnswer && answer !== this.currentQuestion.correctAnswer) {
    return 'incorrect';
  }

  return 'answer-button';
},

    selectAnswer(answer) {
      this.selectedAnswer = answer;
      setTimeout(() => {
        if (answer === this.currentQuestion.correctAnswer) {
          this.showQuestion = false;
        } else {
          this.selectedAnswer = null;
        }
      }, 1000);
    },

    onLeave(el, done) {
      el.style.opacity = 0;
      done();
    },

  },
  created() {
    this.fetchQuestions();
      
    // Track component visit and level using vue-gtag-next
    const { event } = useGtag();
    event("viewed_theory_exercise", {
  event_category: "Component",
  event_label: `Theory Exercise - Level ${this.level}`, 
  // adding event value if needed
  value: this.level 
});

  },
};

</script>

<style scoped>
.theory-box-root {
  padding: 10px;
}
.theory-box {
  padding: 20px 20px 20px 20px;
  background-color: #3333334d;
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 80%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #e6e6e6;
  line-height: 1.5;
  font-size: 20px;
  overflow: hidden;
  
}

.answer-button {
  display: block;
  background-color: rgba(128, 128, 128, 0.5); /* 0.5 alpha gray */
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.answer-button:hover {
  background-color: #5da1c97e;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.correct {
  display: block;
  background-color: rgba(34, 206, 71, 0.5); /* 0.5 alpha gray */
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;  pointer-events: none;

}

.incorrect {
  display: block;
  background-color: rgba(206, 34, 34, 0.5); /* 0.5 alpha gray */
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;  pointer-events: none;
}
</style>
