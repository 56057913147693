<template>

  <div class="theory-box">
    <div v-html="processedText" ref="theoryContent"></div>
    <div class="continue-button">
      <button class="continue-btn" @click="onContinue">To exercises</button>
    </div>
  </div>
</template>

<script>
import { useGtag } from "vue-gtag-next";

export default {

  data() {
    return {
      theoryText: '',
      processedText: '',
      currentAudio: null, // Reference to currently playing audio
      isPlaying: false // Track whether audio is currently playing
    };
  },
  mounted() {
    this.fetchTheoryText();
      window.scrollTo(0, 0);
      
    // Track component visit and level using vue-gtag-next
    const { event } = useGtag();
    event("viewed_theory_box", {
  event_category: "Component",
  event_label: `Theory Box - Level ${this.level}`, 
  // adding event value if needed
  value: this.level 
});

  },

  
  updated() {
    this.addClickListenersToImages();
  },
  props: {
    level: {
      type: Number,
      required: true
    }
  },

  methods: {
    onContinue() {
      this.$emit('continue');
    },
    async fetchTheoryText() {
      try {
        // Assuming this.level contains the selected level index
        const levelNumber = this.level; // Add 1 to make it 1-based if needed
        const response = await fetch(`TheoryLevelTxt/TheoryLevel${levelNumber}.txt`);

        if (response.status === 200) {
          this.theoryText = await response.text();
          this.processTextForMedia();
        } else {
          console.error('Failed to fetch the theory text:', response.statusText);
        }
      } catch (error) {
        console.error('There was an error fetching the theory text:', error);
      }
    },

    processTextForMedia() {
      this.processedText = this.theoryText.replace(
        /imgsrc(\d+)(withaudio(\d+))?/g,
        (match, imgNumber, _, audioNumber) => {
          const imgUrl = require(`@/assets/ImagesTheory/imgsrc${imgNumber}.png`);
const audioTag = audioNumber
  ? `
    <div class="audio-img-container">
      <div class="centered">
        <img src="${imgUrl}" data-audio="${audioNumber}" class="audio-img" />
        <div class="play-icon"></div>
      </div>
    </div>`
  : `<div class="centered"><img src="${imgUrl}"/></div>`;


          return audioTag;
        }
      );
    },

    addClickListenersToImages() {
      if (this.$refs.theoryContent) {
        const images = this.$refs.theoryContent.querySelectorAll('img[data-audio]');
        images.forEach(img => {
          img.addEventListener('click', (event) => {
            const audioNumber = event.target.getAttribute('data-audio');
            if (!this.isPlaying) {
              this.playAudio(audioNumber);
            } else {
              this.stopAudio();
            }
          });
        });
      }
    },

    playAudio(audioNumber) {
      // If there's a currently playing audio, stop it
      this.stopAudio();

      const audioPath = require(`@/assets/AudiosTheory/withaudio${audioNumber}.mp3`);
      this.currentAudio = new Audio(audioPath);
      this.currentAudio.play();

      // Set isPlaying to true while audio is playing
      this.isPlaying = true;

      // Add an event listener to track when audio finishes playing
      this.currentAudio.addEventListener('ended', () => {
        this.isPlaying = false;
      });
    },

    stopAudio() {
      // If there's a currently playing audio, stop it
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio.currentTime = 0; // Reset to the beginning
        this.isPlaying = false;
      }
    }
  }
};
</script>
<style>
.theory-box {
  padding: 20px 20px 20px 20px;
  background-color: #3333334d;
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 90%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #e6e6e6;
  line-height: 1.5;
  font-size: 20px;
  overflow: hidden;
}
.theory-box img {
    margin-top: 40px;
    max-width: 100%; /* This ensures the image won't overflow its container */
    height: auto; /* This maintains the aspect ratio of the image */
    max-height: 300px; /* Adjust this value as per your requirements */
    align-self: center;
}
.continue-btn {
    width: 100%;         /* Occupy the full width of its parent */
    padding: 25px 15px;  /* Padding for button content */
    border: none;        /* No border */
    margin-top: 50px;

    border-radius: 8px;  /* Rounded corners */
    background-color: rgba(0, 119, 255, 0.308);  /* Green background color */
    color: white;        /* White text color */
    font-size: 20px;     /* Font size */
    cursor: pointer;     /* Hand cursor on hover */
    transition: background-color 0.3s;  /* Smooth transition effect */
}

.continue-btn:hover {
    background-color: #5cb3e68e;  /* Slightly darker green on hover */
}
.highlightsimple {
  background-color: rgba(0, 128, 128, 0.5); /* Turquoise green with 50% transparency */
  border-radius: 5px; /* Rounded corners */
  color: rgb(0, 0, 0); /* Black text color */
  padding: 0 10px; /* 5px padding on the left and right */
  font-weight: bold; /* Add any additional styles you want for highlighting */
}
.highlightdark {
  background-color: rgba(0, 119, 255, 0.308); /* Turquoise green with 50% transparency */
  border-radius: 5px; /* Rounded corners */
  color: rgb(44, 44, 44); /* Black text color */
      font-size: 20px;     /* Font size */
      font-family: 'Roboto', sans-serif;  /* Set the font to Roboto */

  padding: 0 5px; /* 5px padding on the left and right */
  font-weight: normal; /* Add any additional styles you want for highlighting */
}
.highlighttitle {
  background-color: rgba(0, 0, 0, 0.849); /* Turquoise green with 50% transparency */
  border-radius: 5px; /* Rounded corners */
  color: rgb(187, 187, 187); /* Black text color */
      font-size: 20px;     /* Font size */
      font-family: 'Roboto', sans-serif;  /* Set the font to Roboto */

  padding: 0 5px; /* 5px padding on the left and right */
  font-weight: normal; /* Add any additional styles you want for highlighting */
}
.highlighteng {
  background-color: rgba(0, 78, 167, 0.233); /* Turquoise green with 50% transparency */
  border-radius: 5px; /* Rounded corners */
  color: rgb(226, 226, 226); /* Black text color */
      font-size: 20px;     /* Font size */
      font-family: 'Roboto', sans-serif;  /* Set the font to Roboto */

  padding: 0 5px; /* 5px padding on the left and right */
  font-weight: normal; /* Add any additional styles you want for highlighting */
}
.highlight {
  background-color:  #5da1c97e; /* Turquoise green with 50% transparency */
  border-radius: 15px; /* Rounded corners */
  color: rgb(29, 29, 29); /* Black text color */
  padding: 10px; /* 5px padding on the left and right */
  font-weight: bold; /* Add any additional styles you want for highlighting */
}
.audio-img {
  position: relative;
}

.audio-img-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.centered {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center horizontally */
}
.play-icon {

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
    pointer-events: none; /* Make the play icon transparent to clicks */

}

.play-icon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent white;
}

/* Add a transition effect to play-icon visibility */
.play-icon.vanish {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.audio-img:hover .play-icon {
  visibility: visible; /* Show the play icon on image hover */
  transition: visibility 0s, opacity 0.5s linear;
}
</style>
