<template>
    <div class="theory-box-root">
  
    <div class="theory-box">
      <transition name="fade" @after-leave="afterLeave">
        <div v-if="showQuestion && currentQuestion">
          <p>{{ currentQuestion.question }}</p>
  <div
    v-for="item in filteredShuffledAnswers"
    :key="item.index"
    :class="buttonClass(item.index)"
    @click="selectAnswer(item.answer)"
  >
    {{ item.answer }}
  </div>
  
  
        </div>
      </transition>
    </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
    minLevel: {
      type: Number,
      required: true
    },
    maxLevel: {
      type: Number,
      required: true
    }
  },
    data() {
      return {
        questionIndex: 0,
        questions: [],
        selectedAnswer: null,
        showQuestion: true,
      };
    },

  
    computed: {
  filteredShuffledAnswers() {
    return this.shuffledAnswers
      .map((answer, index) => ({ answer, index })) // Map to include original index
      .filter(item => item.answer && item.answer.trim() !== '');
  },
  
  
      currentQuestion() {
        return this.questions[this.questionIndex];
      },
      shuffledAnswers() {
        if (!this.currentQuestion) return [];
        let answers = [...this.currentQuestion.answers];
        for (let i = answers.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [answers[i], answers[j]] = [answers[j], answers[i]];
        }
        return answers;
      },
    },
    methods: {
        afterLeave() {
      this.selectedAnswer = null;
      this.showQuestion = true;
      this.fetchRandomQuestion();  // Fetch a new random question after the previous question is answered
    },
  
  
    async fetchRandomQuestion() {
        const level = Math.floor(Math.random() * (this.maxLevel - this.minLevel + 1)) + this.minLevel;  
        console.log(level)

        const response = await fetch(`/TheoryExercisesTxt/TheoryExercise${level}.txt`);
      console.log('asdasd')
      const data = await response.text();
      const lines = data.split("\n");
      const questions = [];

      for (let i = 0; i < lines.length; i += 5) {
        questions.push({
          question: lines[i],
          answers: [lines[i + 1], lines[i + 2], lines[i + 3], lines[i + 4]],
          correctAnswer: lines[i + 1],
        });
      }

      // Select a random question from the fetched questions
      this.questions = [questions[Math.floor(Math.random() * questions.length)]];
    },
    
  buttonClass(index) {
    const answer = this.shuffledAnswers[index];
  
    if (this.selectedAnswer === this.currentQuestion.correctAnswer && answer === this.currentQuestion.correctAnswer) {
      return 'correct';
    }
    
    if (answer === this.selectedAnswer && answer !== this.currentQuestion.correctAnswer) {
      return 'incorrect';
    }
  
    return 'answer-button';
  },
  
  selectAnswer(answer) {
    this.selectedAnswer = answer;
    setTimeout(() => {
        if (answer === this.currentQuestion.correctAnswer) {
            this.showQuestion = false;

            // Generate a random number between 1 and 3
            const randomNumber = Math.floor(Math.random() * 3) + 1;
            console.log(randomNumber);  // Log the random number to the console

            // Decide the next course of action based on the random number
            if (randomNumber === 1) {
                console.log('Going to the next question...');
                this.showQuestion = true;  // Show the next question
                this.fetchRandomQuestion();  // Fetch the next question
            } else if (randomNumber === 2) {
                console.log('Emitting startPractiseVocab event...');
                this.$emit('startPractiseVocab', this.minLevel, this.maxLevel);
            } else if (randomNumber === 3) {
                console.log('Emitting startPractiseTranslate event...');
                this.$emit('startPractiseTranslate', this.minLevel, this.maxLevel);
            }
        } else {
            this.selectedAnswer = null;
        }
    }, 1000);
},

  },

  created() {
    this.$emit('startPractise', this.minLevel, this.maxLevel);
    console.log('created')
    this.fetchRandomQuestion();
  },
};

  </script>
  
  <style scoped>
  .theory-box-root {
    padding-top: 0px;
    
  }
  .theory-box {
    background-color: #3333334d;
    border-radius: 8px;
    box-sizing: border-box;
    max-width: 80%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    margin-left: auto;
    margin-right: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #e6e6e6;
    line-height: 1.5;
    font-size: 20px;
    overflow: hidden;
    
  }
  
  .answer-button {
    display: block;
    background-color: rgba(128, 128, 128, 0.5); /* 0.5 alpha gray */
    color: white;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .answer-button:hover {
    background-color: #5da1c97e;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  
  .correct {
    display: block;
    background-color: rgba(34, 206, 71, 0.5); /* 0.5 alpha gray */
    color: white;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;  pointer-events: none;
  
  }
  
  .incorrect {
    display: block;
    background-color: rgba(206, 34, 34, 0.5); /* 0.5 alpha gray */
    color: white;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;  pointer-events: none;
  }
  </style>
  