<template>
  <div class="grammar-list">
    <div 
      v-for="(item, index) in items" 
      :key="index" 
      class="grammar-item"
      @click="toggleImage(index)"
    >
      <div class="item-header">
        <span>{{ item.name }}</span>
      </div>

      <div class="item-content" :class="{ 'active': item.showImage }">
        <img 
  v-if="item.showImage"
  :src="getImage(item.imageUrl)" 
  alt="Grammar Item"
  ref="images"
  @load="onImageLoad(index)"
>

      </div>
    </div>
  </div>
</template>


<script>
export default {
name: 'GrammarList',
data() {
  return {
    items: [
      { name: 'The personal pronouns', imageUrl: require('@/assets/Tables/taula1pronouns.png'), showImage: false },
      { name: 'The accents', imageUrl: require('@/assets/Tables/accentsgrammar.png'), showImage: false },
      { name: 'The articles', imageUrl: require('@/assets/Tables/taula3articles.png'), showImage: false },
      { name: 'The gender', imageUrl: require('@/assets/Tables/taula3gender.png'), showImage: false },
      { name: 'The plural', imageUrl: require('@/assets/Tables/taula4plural.png'), showImage: false },
      { name: 'The adjective', imageUrl: require('@/assets/Tables/taula5adjective.png'), showImage: false },
      { name: 'The possessive', imageUrl: require('@/assets/Tables/taula8possessives.png'), showImage: false },
      { name: 'The reflexive', imageUrl: require('@/assets/Tables/taula15reflexives.png'), showImage: false },
      { name: 'The verb Ser', imageUrl: require('@/assets/Tables/taula2ser.png'), showImage: false },
      { name: 'The verb Estar', imageUrl: require('@/assets/Tables/taula22estar.png'), showImage: false },
      { name: 'The regular -ar verbs', imageUrl: require('@/assets/Tables/taula12regularverbs.png'), showImage: false },
      { name: 'The regular -er verbs', imageUrl: require('@/assets/Tables/taula13regular2.png'), showImage: false },
      { name: 'The regular -ir verbs', imageUrl: require('@/assets/Tables/taula14regular3.png'), showImage: false },
      { name: 'The verb Haber', imageUrl: require('@/assets/Tables/taula9haber.png'), showImage: false },
      { name: 'The verb Tener', imageUrl: require('@/assets/Tables/taula10tener.png'), showImage: false },
      { name: 'The verb Poder', imageUrl: require('@/assets/Tables/taula11poder.png'), showImage: false },
      { name: 'The numbers', imageUrl: require('@/assets/Tables/taula7numbers.png'), showImage: false },
      // Add more items as needed
    ]
  };
},
methods: {
  onImageLoad(index) {
  this.$nextTick(() => {
    const content = this.$el.querySelectorAll('.item-content')[index];
    if (content) {
      content.style.maxHeight = content.scrollHeight + 'px';
    }
  });
},

toggleImage(index) {
  this.items = this.items.map((item, idx) => ({
    ...item,
    showImage: idx === index ? !item.showImage : false
  }));

  this.$nextTick(() => {
    const content = this.$el.querySelectorAll('.item-content')[index];
    if (content) {
      content.style.maxHeight = this.items[index].showImage ? content.scrollHeight + 'px' : '0';
    }
  });
},
  getImage(imageUrl) {
    return imageUrl;
  }
}
}
</script>
  <style scoped>
  .level-list {
  box-sizing: border-box;
  width: 80%;
  margin-top: 100px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
font-family: 'Roboto', sans-serif;  /* Set the font to Roboto */
  font-weight: 400;                   /* Regular weight */
  color: #e6e6e6;                        /* Dark gray color for good contrast */
  line-height: 1.5;                   /* Improved readability with line-height */
  font-size: 20px;                    /* Set a base font size */
  
}

.level-list h1, .level-list h2, .level-list h3 {
  font-weight: 700;  /* Bold for headings */
  margin-top: 0;     /* Remove default margins */
}

.level-list p {
  margin-bottom: 1em;  /* Space between paragraphs */
}



.level-item {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 20px;
  background-color: #3333334d; ;
  margin-bottom: 10px;
  border-radius: 8px;
 

  transition: background-color 0.3s;
}
.grammar-item:hover {
  background-color: #5da1c97e !important;
}

.level-item:hover {
  background-color: #5da1c97e;
}

  .grammar-list {
    box-sizing: border-box;
  width: 80%;
  margin-top: 50px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
font-family: 'Roboto', sans-serif;  /* Set the font to Roboto */
  font-weight: 400;                   /* Regular weight */
  color: #e6e6e6;                        /* Dark gray color for good contrast */
  line-height: 1.5;                   /* Improved readability with line-height */
  font-size: 20px;                    /* Set a base font size */
  
  }
  
  .grammar-item {
    /* Removed 'display: block;' and 'max-width', 'height' properties */
    font-weight: 700;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #3333334d;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: background-color 0.3s;
}

  
.grammar-item img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: 10px;
}
  .item-content.active {
  max-height: 500px; /* You can adjust this value or calculate it dynamically */
}

.item-header {
  order: 1; /* Ensures the header is always on top */
}

.item-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out; /* Animation effect */
  order: 2; /* Ensures the content is below the header */
}
  </style>
  