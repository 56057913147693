<template>
  <div class="navigation-bar">
    <!-- Logo on the left -->
    <div class="logo" @click="emitLogoClick">
      <img :src="logo" alt="Label Spanish">
    </div>

    <!-- Discussion button on the right -->
    <div class="nav-buttons">
      <button class="discussion-button" @click="emitDiscussionClick">Cheat</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    emitLogoClick() {
      this.$emit('logoClicked');
    },
    emitDiscussionClick() {
      this.$emit('showForumComponent');
    }
  },

  name: 'NavigationBar',
  data() {
    return {
      logo: require('@/assets/logo.png') // '@' alias points to the 'src' directory in a default Vue CLI setup
    }
  }
}
</script>
<style scoped>

.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  background-color: #3333334d; 
    backdrop-filter: blur(10px);

  position: fixed; /* Fixed position */
  top: 0; /* Always stick to the top */
  left: 0; /* Full width by defining left and right */
  right: 0;
  z-index: 10000; /* To ensure it stays above other content */
  color: white;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

}

.logo img {
  width: 65px;
  height: 50px;
}


.discussion-button {
  font-size: 0.9em;

  margin-left: 20px;
  background-color: #4da6da86;
  border: none;
  padding: 5px 15px;
  color: rgba(255, 255, 255, 0.815);
  cursor: pointer;
  border-radius: 5px; /* Added rounded corners */
}

.discussion-button:hover {
  background-color: #5aa8d4cb; /* Adjusted hover effect */
  color: #ffffffc7;
}

</style>

