<template>
  <div class="messages-list">
    <div class="top-title">
      {{ levelName }}
    </div>

    <div
      v-for="message in visibleMessages"
      :key="message.id"
      class="message"
      :class="message.type"
      @click="message.type === 'incoming' ? toggleMessage(message.id) : null"
    >
      {{ message.text }}
    </div>
  </div>
</template>


<script>
export default {
  computed: {
    visibleMessages() {
      return this.messages.filter(
        message =>
          message.type === 'incoming' ||
          (message.type === 'outgoing' && message.show)
      );
    },
  },
  props: {
    levelName: String,
    levelIndex: Number,
  },
  data() {
    return {
      messages: [],
    };
  },
  methods: {
    toggleMessage(id) {
      const outgoingMessage = this.messages.find(
        message => message.id === id + 1
      );
      if (outgoingMessage) {
        outgoingMessage.show = !outgoingMessage.show;
      }
    },
  },
  async created() {
    const incomingMessagesPath = `./DiscussionTxt/${this.levelIndex+1}.txt`;
    const outgoingMessagesPath = `./DiscussionAnswersTxt/${this.levelIndex+1}.txt`;

    const incomingMessagesResponse = await fetch(incomingMessagesPath);
    const outgoingMessagesResponse = await fetch(outgoingMessagesPath);

    const incomingMessagesText = await incomingMessagesResponse.text();
    const outgoingMessagesText = await outgoingMessagesResponse.text();

    const incomingLines = incomingMessagesText.split('\n');
    const outgoingLines = outgoingMessagesText.split('\n');

    // assuming the lengths of incomingLines and outgoingLines are equal,
    // or at least you want to pair them up to the length of the shorter array
    const pairs = incomingLines.map((incoming, index) => [
      { id: index * 2, text: incoming, type: 'incoming' },
      { id: index * 2 + 1, text: outgoingLines[index], type: 'outgoing' },
    ]);

    this.messages = pairs.flat();
  },
};
</script>

<style scoped>
.top-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color:#e9e9e9;
}
.message {
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px;
  width: 100%; /* Take full width */
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}
.incoming, .outgoing {
  width: max-content; /* Take only as much width as needed */
  max-width: 80%; /* Maximum width to take */
}

.incoming {
  background-color: #54a8c4;
  color: black;
  border-bottom-left-radius: 0;
  margin-left: 10px; /* Adjust as per requirement */
}

.outgoing {
  background-color: #afcae9e1;
  color: black;
  border-bottom-right-radius: 0;
  margin-right: 10px; /* Adjust as per requirement */
  margin-left: auto; /* Push the element to the right */
}
.messages-list {
  max-width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.content {
  font-size: 1em;
}



</style>
