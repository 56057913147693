<template>
<div class="root">
    <div class="translate-box">
        <p>{{ currentQuestion }}</p>
        <p v-if="answerIsCorrect" class="correctAnswer">{{ currentAnswer }}</p>
        <input type="text" v-model="userInput" @input="checkAnswer" :class="{ 'answer-correct': answerIsCorrect }">

        <div class="lightbulb-container">
<img :src="lightbulb" alt="Hint" @click="toggleLightbulb"/>
<p>{{ lightbulbText }}</p>
        </div>
    </div>
    <LevelCompleted v-if="allQuestionsExhausted" :isVisible="allQuestionsExhausted" @continueFinished="reset"/>

</div>
</template>



<script>
import { useGtag } from "vue-gtag-next";

import lightbulb from '@/assets/lightbulb.png';
import LevelCompleted from '@/components/LevelCompleted.vue'; 

export default {
  components: {
    LevelCompleted
  },
  props: {
    level: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      allQuestionsExhausted: false,
        hints:[],
        clueMap: {
    'N': "Noun",
    '?': "Question/exclamation sign",
    'F': "Question word",
    'D': "Adverb",
    'E': "Preposition",
    'Q': "Quantifier",
    'V': "Verb",
    'P': "Possessive",
    '!': "Infinitive",
    'O': "Verb (present indicative)",
    'T': "Article",
    'B': "Number",
    'J': "Adjective",
    'M': "Impersonal se",
    'X': "Verb (aux. present. ind.)",
    'S': "Demonstrative",
    'R': "Personal pronoun",
    'Y': "Conjunction (coord.)",
    'U': "Relative pronoun/conjunction",
    'G': "Verb (past. ind.)",
    'W': "Verb (present subjunctive)",
    'L': "Interjection (ay, hola, ojalá...)",
    'H': "Conjunction (time)",
    'K': "Verb (imperative)",
    ',': "Coma",
    'I': "Verb (imperfect, subjunctive)",
    'C': "Ordinal number",
    '1': "Verb (future, indicative)",
    '2': "Verb (conditional, indicative)",
    '3': "Conjunction (conditional)",
    '4': "Verb (gerund)",
    '5': "Verb (infinitive) + I.O.Pronoun",
    '6': "Verb (participle)",
    '7': "Indefinite pronoun",
    '8': "Verb (aux., conditional, indicative)",
    '9': "Verb (aux., future, indicative)",
    '0': "Reflexive se",
    '+': "Verb (aux., imperfect, indicative)",
    '€': "Verb (imperfect, indicative)",
    '%': "Comparative",
    '$': "Verb (reflexive)",
    '@': "Conjunction (location)",
    '#': "Indefinite pronoun",
    ':': "Conjunction (conditional)"
},

        lightbulb,
                    lightbulbVisible: false,
            lightbulbText: " ",
      questions: [],
      answers: [],
      questionIndex: 0,
      userInput: "",
          answerIsCorrect: false

    };
  },
watch: {
  currentQuestion(newQuestion, oldQuestion) {
    if (newQuestion !== oldQuestion) {
      this.userInput = "";
                  this.lightbulbVisible = false;
            this.lightbulbText = " "; 
    }
  }
},

  computed: {
    currentQuestion() {
      return this.questions[this.questionIndex];
    },
    currentAnswer() {
      return this.answers[this.questionIndex];
    }
  },

  methods: {
    reset() {
      this.allQuestionsExhausted = false;
      this.$emit('allQuestionsExhausted');
    },
    toggleLightbulb() {
        this.lightbulbVisible = !this.lightbulbVisible;
        this.lightbulbText = this.lightbulbVisible ? this.hints[this.questionIndex] : " ";
    },
async fetchQuestionsAndAnswers() {
    console.log('Fetching questions and answers...');

    try {
        const questionResponse = await fetch(`./TranslateExercisesTxt/TranslateQuestionsLevel${this.level}.txt`);
        const answerResponse = await fetch(`./TranslateExercisesTxt/TranslateAnswersLevel${this.level}.txt`);

        const questionsDataRaw = await questionResponse.text();
        const answersData = await answerResponse.text();

this.questions = questionsDataRaw.split("\n").map(question => {
    const parts = question.split('*');
    if (parts.length > 1) {
        const hintCharacters = parts[1].trim().split('');
        const hintWords = hintCharacters.map(char => this.clueMap[char] || char);
        this.hints.push(hintWords.join(' - '));
        return parts[0].trim();
    }
    this.hints.push(" "); // Default to empty space if no hint present
    return question;
});

        this.answers = answersData.split("\n");
        this.$forceUpdate();

    } catch (error) {
        console.error('Failed to load translation exercises:', error);
    }
},



checkAnswer() {
  const normalizedInput = this.userInput.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const normalizedAnswer = this.currentAnswer.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  if (normalizedInput === normalizedAnswer) {
    this.answerIsCorrect = true;
    this.playAudio(this.questionIndex);
  }
},


  playAudio(questionNumber) {
    const audioPath = `./TranslateExercisisAudios/Level${this.level}/${questionNumber+1}.mp3`;
    const audio = new Audio(audioPath);

    // Listen for the 'ended' event
    audio.addEventListener('ended', () => {
      // Once the audio has ended, wait for an additional 2 seconds
      setTimeout(() => {
        this.moveToNextQuestion();
      }, 2000);  // 2000ms equals 2 seconds
    });

    audio.play();
  },

  moveToNextQuestion() {
    this.questionIndex++;
    if (this.questionIndex >= this.questions.length) {
      this.allQuestionsExhausted = true;
    } else {
      this.answerIsCorrect = false;
    }
  },
  },
created() {
  console.log("TranslateExercise created");
      this.fetchQuestionsAndAnswers();
              // Track component visit and level using vue-gtag-next
              const { event } = useGtag();
    event("viewed_trans_exercise", {
  event_category: "Component",
  event_label: `trans Ex - Level ${this.level}`, 
  // adding event value if needed
  value: this.level 
});

},
mounted() {
  console.log("TranslateExercise mounted");
},
beforeUnmount() {
  console.log("TranslateExercise about to be destroyed");
}
}
</script>

<style scoped>
.root {
    padding-top: 150px;
}
.lightbulb-container {
    display: flex;
    align-items: center;  
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding-top: 10px;
}

.lightbulb-container img{
    cursor: pointer;  
    margin-right: 10px;  
    width: 30px;
    height: 30px;
}

.lightbulb-container p{
    padding-top: 10px;
}


input[type="text"] {
  border: 1px solid #aa3f3f;
  border-color: var(--input-border-color);
}
.correctAnswer{
    color:#5eb8ece8;
}
.input.answer-correct {
background-color: green;
}

.translate-box {
    padding: 20px;
    background-color: #3333334d;
    border-radius: 8px;
    box-sizing: border-box;
    max-width: 80%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    margin-left: auto;
    margin-right: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #e6e6e6;
    line-height: 1.5;
    font-size: 20px;
    overflow: hidden;
}

input[type="text"] {
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #58575775;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
    background-color: #fcfcfc83;
}
</style>

