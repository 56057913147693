<template>
    <div v-if="visible" class="popup" @click.self="closePopup">
      <div class="content">
  
        <h3>Select Levels</h3>
        <div class="level-selection">
          <label for="from-level"><h6>From:</h6></label>
          <select id="from-level" v-model="selectedMinLevel" @change="adjustMaxLevelOptions">
            <option 
              v-for="level in levels" 
              :key="level" 
              :value="level"
            >
              {{ level }}
            </option>
          </select>
  
          <label for="to-level"><h6>To:</h6></label>
          <select id="to-level" v-model="selectedMaxLevel">
            <option 
              v-for="level in maxLevelOptions" 
              :key="level" 
              :value="level"
            >
              {{ level }}
            </option>
          </select>
        </div>
        
        <!-- This line is removed/commented out
        <div>Level: {{ selectedMinLevel }} - {{ selectedMaxLevel }}</div>
        -->
  
        <button class="continue-btn" @click.stop="startPractise">Start Practice</button>
      </div>
    </div>
  </template>

  
  <script>
  export default {
    props: {
      visible: Boolean,
    },
    data() {
      return {
        selectedPracticeType: '',
        selectedMinLevel: 1,
        selectedMaxLevel: 54,
        levels: Array.from({length: 54}, (_, i) => i + 1),
        maxLevelOptions: Array.from({length: 54}, (_, i) => i + 1),
        practiceTypes: [
          {label: 'Theory', value: 'theory'},
          {label: 'Vocabulary', value: 'vocabulary'},
          {label: 'Translating', value: 'translating'}
        ]
      };
    },
  
    methods: {
      closePopup() {
        this.$emit('popupClosed');
      },
      startPractise() {
        console.log('startpractise')
        console.log('startpractise');
  this.$emit('startPractise', {
    minLevel: this.selectedMinLevel,
    maxLevel: this.selectedMaxLevel,
  });

    this.closePopup();
  },



      selectPracticeType(type) {
        this.selectedPracticeType = type;
      },
      adjustMaxLevelOptions() {
        this.maxLevelOptions = this.levels.filter(level => level >= this.selectedMinLevel);
        if (this.selectedMaxLevel < this.selectedMinLevel) {
          this.selectedMaxLevel = this.selectedMinLevel;
        }
      }
    },
    
    created() {
      this.adjustMaxLevelOptions();
    }
  };
  </script>
  
  <style scoped>

  
  .switch.active {
    background-color: #ccc;
  }
  
  .level-selection {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .level-selection select {
    
    font-size: 0.8em;
    padding: 5px;
    opacity: 0.9;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: rgba(206, 211, 211, 0.76);
  }
  </style>
  