<template>
  <div class="level-list">
    <div class="icon-boxes">
      <div class="icon-box" @click="$emit('practiseClicked')">
        <img src="@/assets/exercise.png" alt="Practise">
        <span>Practise</span>
      </div>
      <div class="icon-box" @click="$emit('glossaryClicked')">
  <img src="@/assets/glossary.png" alt="Glossary">
  <span>Glossary</span>
</div>

      <div class="icon-box" @click="$emit('grammarClicked')"> <!-- This is the Grammar button -->
        <img src="@/assets/grammar.png" alt="Grammar">
        <span>Grammar</span>
      </div>
    </div>

    <div 
      v-for="(level, index) in levels" 
      :key="index" 
      class="level-item"
      @click="selectLevel(level, index)"
    >


      <span>
        <strong class="cyan-text">{{ getLevelLabel(level.name) }}</strong>
{{ getLevelDescription(level.name) }}

      </span>
      <img :src="level.isCompleted ? completedImageUrl : defaultImageUrl" alt="Level Status">
    </div>
  </div>
</template>

<script>
export default {
  name: 'LevelList',
      mounted() {
    document.body.style.overflow = ''; // Re-enable scrolling
      window.scrollTo(0, 0);
          // Send page view to Google Analytics


},

  data() {
    return {
      levels: [],
        defaultImageUrl: require('@/assets/forwardicon.png'),
        completedImageUrl: require('@/assets/forwardicon.png')
    
    };
  },
  created() {
    // Load level names from LevelsNames.txt
    this.loadLevels();
  },
  methods: {
    async loadLevels() {
      try {
        const response = await fetch('/LevelNames.txt');
        const text = await response.text();
        const levelNames = text.split('\n'); // Assuming one name per line

        this.levels = levelNames.map(name => ({
          name: name.trim(),
          isCompleted: false // or any logic to determine if the level is completed
        }));
      } catch (error) {
        console.error('Failed to load level names:', error);
      }
    },
    getLevelLabel(levelName) {
    const parts = levelName.split(':'); // Splitting by colon first
    if (parts.length > 0) {
        return parts[0].trim(); // This will give the "Level X" part
    }
    return ''; // Return empty string if no label found
},


selectLevel(level, index) {
  if (level.name.includes('Dictation')) {
    this.$emit('dictationSelected', index + 1); // Add 1 to convert index to level number
    console.log("Dictation selected")
  } else {
    this.$emit('levelSelected', index);
  }
},

  getLevelDescription(levelName) {
    const parts = levelName.split(':');
    return parts.length > 1 ? parts[1].trim() : levelName;
  },

     
     
    
  }
}
</script>


<style scoped>
.level-list {
  box-sizing: border-box;
  width: 90%;
  margin-top: 30px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
font-family: 'Roboto', sans-serif;  /* Set the font to Roboto */
  font-weight: 400;                   /* Regular weight */
  color: #e6e6e6;                        /* Dark gray color for good contrast */
  line-height: 1.5;                   /* Improved readability with line-height */
  font-size: 20px;                    /* Set a base font size */
  
}

.level-list h1, .level-list h2, .level-list h3 {
  font-weight: 700;  /* Bold for headings */
  margin-top: 0;     /* Remove default margins */
}

.level-list p {
  margin-bottom: 1em;  /* Space between paragraphs */
}



.level-item {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 20px;
  background-color: #3333334d; ;
  margin-bottom: 10px;
  border-radius: 8px;
 

  transition: background-color 0.3s;
}

.level-item:hover {
  background-color: #5da1c97e;
}

.level-item img {
  width: 30px; 
  height: 30px; 
}
.cyan-text {
  color: rgb(255, 255, 255);
}
.icon-boxes {
  display: flex;
  justify-content: center; /* Center the boxes */
  margin-bottom: 20px; /* Space between the boxes and the level list */
}

.icon-boxes {
  display: flex;
  justify-content: center; /* Center the boxes */
  margin-bottom: 20px; /* Space between the boxes and the level list */
}

.icon-box {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #3333334d;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.icon-box:not(:last-child) {
  margin-right: 20px; /* Add right margin to all boxes except the last one */
}

.icon-box:hover {
  background-color: #5da1c97e;
}

.icon-box img {
  width: 50px;  /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
}

.icon-box span {
  margin-top: 10px; /* Space between the icon and the text */
  color: white; /* Text color */
}

</style>
