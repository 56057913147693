<template>
  <div v-if="isVisible" class="popup">
    <div class="content">
      <p>Level completed!</p>
      <button class="continue-btn" @click="continueFinished">Continue</button>
    </div>
  </div>
</template>
  
  <script>
  export default {
    props: ['isVisible'],
    methods: {
      continueFinished() {
        this.$emit('continueFinished');
      }
    }
  }
  </script>
 <style >
 .popup {
   position: fixed;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   background: rgba(0, 0, 0, 0.5); /* Dark background with opacity */
   display: flex;
   justify-content: center;
   align-items: center;
   backdrop-filter: blur(5px); /* Add blur effect to the background */
 }
 
 .content {
   background: rgba(50, 50, 50, 0.815); /* Grayish and a bit transparent background */
   padding: 20px;
   width: 60%;
   border-radius: 15px; /* Rounded corners */
   color: rgba(255, 255, 255, 0.9); /* Whitish letters */
   text-align: center;
   font-size: 25px;
  }

 .continue-btn {
   width: 100%;
   padding: 25px 15px;
   border: none;
   margin-top: 20px;
   border-radius: 8px;
   background-color: #5da1c97e;
   color: white;
   font-size: 20px;
   cursor: pointer;
   transition: background-color 0.3s;
 }

 .continue-btn:hover {
   background-color: rgba(0, 119, 255, 0.308); /* Slightly darker on hover */
 }
 </style>