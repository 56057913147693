<template>
    <div class="dictation-component" v-if="dictationText">
      <div class="sentences-container">
        <div v-for="(sentence, index) in dictationText" :key="index" 
             :class="getSentenceClass(index)"
             @click="handleClick(index)">
          <div v-if="shownTranslations[index]">
            {{ dictationTranslation[index] }}
          </div>
          <div v-else>
            {{ sentence }}
          </div>
        </div>
      </div>
      <input type="text" v-model="userInput" @input="checkInput" class="cool-input" ref="inputField" />
    </div>
    <audio ref="audioPlayer" :src="currentAudioSrc" @loadeddata="onAudioLoaded" @ended="onAudioEnded"></audio>
        <transition name="fade">
  <div v-if="allSentencesExhausted" class="modal">
    <div class="modal-content">
      <LevelCompleted @continueFinished="reset" :isVisible="allSentencesExhausted"/>
    </div>
  </div>
</transition>
</template>

  <script>
  import LevelCompleted from './LevelCompleted.vue'; // Adjust the path according to your file structure

  export default {
    emits: ['allQuestionsExhausted'],  // Add this line

    name: 'DictationComponent',
    props: {
      level: {
        type: Number,
        required: true,
      },
    },
    data() {
        
      return {
        dictationText: [],
        allSentencesExhausted: false,
        currentAudioSrc: '',  // Added to hold the current audio source URL
        shownTranslations: {},  // Initialize as an object, not a boolean
        dictationTranslation: null,
        currentSentenceIndex: 0,
        userInput: '',
      };
    },

    components: {
    LevelCompleted,
    // ... (your other components if any)
  },
    computed: {

      currentSentence() {
        return this.dictationText[this.currentSentenceIndex];
      },
      currentTranslatedSentence() {
        return this.dictationTranslation[this.currentSentenceIndex];
      }
    },
    
    methods: {
        handleClick(index) {
            if(index === this.currentSentenceIndex) {
                this.playAudio(index);  // Play the audio when the current sentence is clicked
            } else if(index < this.currentSentenceIndex) {
                this.toggleTranslation(index);  // Toggle translation and play audio for previous sentences
            }
        },
        playClickedSentenceAudio(index) {
            if (index <= this.currentSentenceIndex) {
                if (index < this.currentSentenceIndex) {
                    this.toggleTranslation(index);
                }
                this.playAudio(index);
            }
        },
        async playAudio(sentenceIndex) {
            this.currentAudioSrc = `./DictationAudios/${this.level}/${sentenceIndex+1}.mp3`;
        },

        async onAudioLoaded() {
            try {
                await this.$refs.audioPlayer.play();
            } catch (error) {
                console.error('Failed to play audio:', error);
            }
        },

        onAudioEnded() {
            console.log('Audio playback ended');
        },
        getSentenceClass(index) {
        if(index === this.currentSentenceIndex) {
            return 'highlightdark';
        } else if(index < this.currentSentenceIndex) {
            return 'highlightprevious';
        }
        return '';
    },
    normalizeString(str) {
      if (!str) return '';
      return str.normalize("NFD")
                 .replace(/[\u0300-\u036f]/g, "") 
                 .replace(/[^\w\s.]|_/g, "")  // Exclude period from replacement
                 .replace(/\s*[\r\n]+\s*/g, " ")  // Remove newline and carriage return characters
                 .trim()   // Remove any leading or trailing spaces
                 .toLowerCase();                
    },
    // ... (rest of your methods)
    toggleTranslation(index) {
    this.shownTranslations[index] = !this.shownTranslations[index];
    this.playAudio(index);  // Play the audio when the sentence is clicked

},

reset() {
    console.log("asdasd")
    this.$emit('allQuestionsExhausted'); 
},
    checkInput() {
      if (this.userInput && this.currentTranslatedSentence) {
        const normalizedUserInput = this.normalizeString(this.userInput);
        const normalizedCorrectAnswer = this.normalizeString(this.currentTranslatedSentence);

        console.log('Checking input:', normalizedUserInput, normalizedCorrectAnswer);

        if (normalizedUserInput === normalizedCorrectAnswer) {
    console.log('Input matches the correct answer');

    if (this.currentSentenceIndex < this.dictationText.length - 1) {
      this.currentSentenceIndex++;
      console.log('Correct! Moving to the next sentence.');
    } else {
      console.log('Level completed!');
      this.allSentencesExhausted = true;
      return; // End the function to avoid playing the next audio when all sentences are finished
    }

    this.userInput = '';
    this.playAudio(this.currentSentenceIndex);  // Play the audio for the new sentence
  } else {
    console.log('Input does not match the correct answer');
  }
      } else {
        console.log('User input or expected answer is undefined or empty');
      }
    },
},





    async mounted() {
      try {
        const response1 = await fetch(`./DictationEng/${this.level}.txt`);
        const response2 = await fetch(`./DictationTxt/${this.level}.txt`);
        if (response1.ok && response2.ok) {
          const text1 = await response1.text();
          const text2 = await response2.text();
          this.dictationText = text1.split('\n').filter(sentence => sentence.trim() !== '');
          this.dictationTranslation = text2.split('\n').filter(sentence => sentence.trim() !== '');
        } else {
          console.error('Failed to fetch dictation text');
        }
      } catch (error) {
        console.error('Error:', error);
      }
      this.$nextTick(() => {
        this.$refs.inputField.focus();
    });
    this.$nextTick(() => {
            this.$refs.inputField.focus();
        });

        // Added to play the first audio right after mounting the component
        try {
            await this.playAudio(0);  // Use await to handle the promise
        } catch (error) {
            console.error('Error while playing the first audio:', error);
        }
    },
  };
  </script>
  <style scoped>.highlightdark {
    background-color: rgba(119, 170, 230, 0.308); /* Turquoise green with 50% transparency */
    border-radius: 5px; /* Rounded corners */
    color: rgb(44, 44, 44); /* Black text color */
        font-size: 20px;     /* Font size */
        font-family: 'Roboto', sans-serif;  /* Set the font to Roboto */
  
    padding: 0 5px; /* 5px padding on the left and right */
    font-weight: bold; /* Add any additional styles you want for highlighting */
  }
.cool-input {
    margin-top:15px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.1); /* somewhat transparent */
  border: none;
  color: #e6e6e6;
  border-radius: 8px;
}
.highlightprevious {
    background-color: rgba(119, 171, 230, 0.144); /* Turquoise green with 50% transparency */
    border-radius: 5px; /* Rounded corners */
    color: rgb(0, 0, 0); /* Black text color */
    padding: 0 10px; /* 10px padding on the left and right */
    font-style: normal;
}
.sentences-container {
  max-height: calc(40vh - 60px); /* Adjust the value as per your needs */
  overflow-y: auto; 
  padding-bottom: 20px; /* Add space at the bottom for better user experience */
}
/* Add Animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
/* The Modal (background) */
.modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */

  .dictation-component {
    margin-top: 50px;
    background-color: #3333334d;
    border-radius: 8px;
    box-sizing: border-box;
    max-width: 80%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    margin-left: auto;
    margin-right: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #e6e6e6;
    line-height: 1.5;
    font-size: 20px;
    padding: 20px;
  }
  </style>
  