<template>
<div class="root">
  <div class="vocab-box" v-if="currentVocab">
    <p>{{ currentVocab.word }}</p>
    <div
      v-for="(item, index) in shuffledAnswers"
      :key="index"
      :class="buttonClass(index)"
      @click="selectAnswer(item)"
    >
      {{ item }}
    </div>
  </div>
</div>
</template>

<script>
import { useGtag } from "vue-gtag-next";

export default {
  
  data() {
    return {
      vocabList: [],
      vocabIndex: 0,
      selectedAnswer: null,
    };
  },
  props: {
    level: {
      type: Number,
      required: true
    }
  },
  computed: {
    currentVocab() {
      return this.vocabList[this.vocabIndex];
    },
    shuffledAnswers() {
      if (!this.currentVocab) return [];
      let answers = [...this.currentVocab.answers];
      for (let i = answers.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [answers[i], answers[j]] = [answers[j], answers[i]];
      }
      return answers;
    },
  },
  watch: {
    level: {
      immediate: true,
      handler(newValue) {
        if (newValue !== undefined) {
          this.fetchQuestions();
        }
      },
    },
  },
  methods: {
  getRandomAnswers(excludingWord, numberOfAnswers) {
    const allWords = this.vocabList.map(item => item.word);
    const possibleAnswers = allWords.filter(word => word !== excludingWord);

    const randomAnswers = [];
    for (let i = 0; i < numberOfAnswers; i++) {
      const randomIndex = Math.floor(Math.random() * possibleAnswers.length);
      randomAnswers.push(possibleAnswers[randomIndex]);
      possibleAnswers.splice(randomIndex, 1); // Removes the chosen word so it isn't chosen again
    }

    return randomAnswers;
  },
  
  async fetchQuestions() {
    try {
      const response = await fetch(`/VocabExercisesTxt/VocabExercise${this.level}.txt`);
      const data = await response.text();
      const lines = data.split("\n");

      this.vocabList = lines.map(line => {
        const [question, correctAnswer] = line.split('%%');
        const fakeAnswers = this.getRandomAnswers(question, 3); // Gets 3 random answers
        let answers = [correctAnswer, ...fakeAnswers];
        return {
          word: question,
          answers: answers.sort(() => 0.5 - Math.random()),
          correctAnswer: correctAnswer,
        };
      });
    } catch (error) {
      console.error('Failed to load vocab exercises:', error);
    }
  },
    buttonClass(index) {
      const answer = this.shuffledAnswers[index];
      if (this.selectedAnswer === this.currentVocab.correctAnswer && answer === this.currentVocab.correctAnswer) {
        return 'correctlyPressed';
      }
      
      if (answer === this.selectedAnswer && answer !== this.currentVocab.correctAnswer) {
        return 'incorrectlyPressed';
      }

      return 'vocab-answer-button';
    },
playAudio(questionNumber) {
  const audioPath = `./VocabAudios/VocabAudiosLevel${this.level}/${questionNumber + 1}.mp3`;
  const audio = new Audio(audioPath);

  // When the audio ends, go to the next question
  audio.addEventListener('ended', () => {
    this.vocabIndex++;
    this.selectedAnswer = null;
    if (this.vocabIndex >= this.vocabList.length) {
      this.$emit('questionsVocabExhausted');
    }
  });

  audio.play();
},
selectAnswer(answer) {
  this.selectedAnswer = answer;
  if (answer === this.currentVocab.correctAnswer) {
    this.playAudio(this.vocabIndex);
  } else {
    // If the answer is wrong, you might still want a slight delay
    setTimeout(() => {
      this.selectedAnswer = null;
    }, 1000);
  }
},


  },
  created() {
    this.fetchQuestions();

        // Track component visit and level using vue-gtag-next
        const { event } = useGtag();
    event("viewed_vocab_exercise", {
  event_category: "Component",
  event_label: `Vocab Ex - Level ${this.level}`, 
  // adding event value if needed
  value: this.level 
});
  },
};
</script>

<style scoped>
.root {
      padding-top: 100px;

}
.vocab-box {
  padding: 20px 20px 20px 20px;
  background-color: #3333334d;
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 80%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #e6e6e6;
  line-height: 1.5;
  font-size: 20px;
  overflow: hidden;
  
}

.vocab-answer-button {
  display: block;
  background-color: rgba(128, 128, 128, 0.5); /* 0.5 alpha gray */
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vocab-answer-button:hover {
  background-color: #5da1c97e;
}
.correctlyPressed {
  display: block;
  background-color: rgba(34, 206, 71, 0.5); /* 0.5 alpha gray */
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;  pointer-events: none;

}

.incorrectlyPressed {
  display: block;
  background-color: rgba(206, 34, 34, 0.5); /* 0.5 alpha gray */
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;  pointer-events: none;
}
</style>
