<template>
  <div class="root">
    
    <div class="vocab-box" v-if="currentVocab && !isLoading">
      <p>{{ currentVocab.word }}</p>
      <div
        v-for="(item, index) in shuffledAnswers"
        :key="index"
        :class="buttonClass(index)"
        @click="selectAnswer(item)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

  
  
  <script>
  export default {
    props: {
    minLevel: {
      type: Number,
      required: true,
    },
    maxLevel: {
      type: Number,
      required: true,
    },
  },
    data() {
      return {
        isLoading: false,
        vocabList: [],
        vocabIndex: 0,
        
        selectedAnswer: null,
        
      };
    },
    computed: {
    currentVocab() {
      return this.vocabList[this.vocabIndex];
    },
    shuffledAnswers() {
      return this.currentVocab ? this.currentVocab.answers : [];
    },
  },
      methods: {
        getRandomAnswers(allAnswers, excludingAnswer, numberOfAnswers) {
      const possibleAnswers = allAnswers.filter(answer => answer !== excludingAnswer);
      return possibleAnswers.sort(() => Math.random() - 0.5).slice(0, numberOfAnswers);
    },
      
    async fetchQuestions() {
        this.isLoading = true;
        console.log(this.minLevel, this.maxLevel);

        const randomLevel = Math.floor(Math.random() * (this.maxLevel - this.minLevel + 1)) + this.minLevel;  // Adjusted this line
    try {
        const response = await fetch(`/VocabExercisesTxt/VocabExercise${randomLevel}.txt`);
        const data = await response.text();
        const lines = data.split("\n").filter(line => line.trim() !== "");

        const randomIndex = Math.floor(Math.random() * lines.length);
        const selectedLine = lines[randomIndex];
        const [word, correctAnswer] = selectedLine.split('%%');
        
        const allAnswers = lines.map(line => line.split('%%')[1]);
        const fakeAnswers = this.getRandomAnswers(allAnswers, correctAnswer, 3);

        // Combine and shuffle the correct answer and fake answers
        const combinedAnswers = [correctAnswer, ...fakeAnswers];
        this.vocabList = [{
          word,
          answers: this.shuffleArray(combinedAnswers),
          correctAnswer,
          level: randomLevel,
          questionNumber: randomIndex + 1,
        }];
        
        console.log(this.vocabList);
        console.log(this.currentVocab);
        this.isLoading = false;

        return Promise.resolve(); // Resolve the promise here
        
    } catch (error) {
        this.isLoading = false;

        console.error('Failed to load vocab exercises:', error);
    }

},
    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Swap elements
        }
        return array;
    },

    buttonClass(index) {
const answer = this.shuffledAnswers[index];
  if (this.selectedAnswer === this.currentVocab.correctAnswer && answer === this.currentVocab.correctAnswer) {

    return 'correctlyPressed';
   

  }

  if (answer === this.selectedAnswer && answer !== this.currentVocab.correctAnswer) {
    return 'incorrectlyPressed';
  }

  return 'vocab-answer-button';
},


        playAudio() {
      const { level, questionNumber } = this.currentVocab;
      const audioPath = `./VocabAudios/VocabAudiosLevel${level}/${questionNumber}.mp3`;
      const audio = new Audio(audioPath);

      audio.addEventListener('ended', () => {

        const randomNumber = Math.floor(Math.random() * 3) + 1;
      
      if (randomNumber === 1) {
        console.log('Emitting startPractiseTheory');
        this.$emit('startPractiseTheory');  // Emit the startPractiseVocab event
        this.vocabIndex = 0;
          } else if (randomNumber === 2) {
        console.log('Loading new random question');
            this.fetchQuestions();
            this.vocabIndex = 0;
      } else if (randomNumber === 3) {
        console.log('Emitting startPractiseTranslate');
        this.$emit('startPractiseTranslate');
      } else {
        console.log('Unknown case');
      }
    }, 500); // You can adjust the timeout duration as per your requirements.
 
 

      audio.play();
    },

    selectAnswer(answer) {
  this.selectedAnswer = answer;
  if (answer === this.currentVocab.correctAnswer) {
    this.playAudio();
  }
},

  },
      created() {
    this.fetchQuestions();
  },
};
    </script>
    
    <style scoped>
 .root {
      padding-top: 50px;

    
}
.vocab-box {
  padding: 20px 20px 20px 20px;
  background-color: #3333334d;
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 80%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #e6e6e6;
  line-height: 1.5;
  font-size: 20px;
  overflow: hidden;
  
}

.vocab-answer-button {
  display: block;
  background-color: rgba(128, 128, 128, 0.5); /* 0.5 alpha gray */
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vocab-answer-button:hover {
  background-color: #5da1c97e;
}
.correctlyPressed {
  display: block;
  background-color: rgba(34, 206, 71, 0.5); /* 0.5 alpha gray */
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;  pointer-events: none;

}

.incorrectlyPressed {
  display: block;
  background-color: rgba(206, 34, 34, 0.5); /* 0.5 alpha gray */
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;  pointer-events: none;
}
    </style>