
import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag-next";

createApp(App)
  .use(VueGtag, {
    property: {
      id: "G-MD037ZV5Y2" // Replace with your Google Analytics tracking ID
    }
  })
  .mount('#app');
