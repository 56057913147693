<template>
  <div class="glossary-list">
    <div class="spinner-container" v-if="isLoading">
  <div class="loading-spinner"></div>
</div>
    <div v-for="(title, index) in titles" :key="index" class="glossary-item" @click="toggleItem(index)">
      <div>{{ title }}</div>
      <div v-if="selectedItem === index" class="expanded-content">
         
        <div class="section">

          <h3>Vocabulary</h3>
          <div class="scrollable-content">
          <button 
            v-for="(word, i) in glossaryItems[index]?.vocabulary || []" 
            :key="i" 
            @click.stop="onWordClick(word, i, index)"
            class="word-button"
          >
            <span class="original">{{ word.original }}</span>
            <span class="translation">{{ word.translation }}</span>
          </button>
        </div>
      </div>
        <div class="section">
          <h3>Sentences</h3>
          <div class="scrollable-content">
          <button 
            v-for="(sentence, i) in glossaryItems[index]?.sentences || []" 
            :key="i" 
            @click.stop="onSentenceClick(sentence, i, index)"
            class="sentence-button"
          >
            <span class="original">{{ sentence.original }}</span>
            <span class="translation">{{ sentence.translation }}</span>
          </button>
        </div>
        </div>
        
      </div>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      isLoading: false,

      selectedItem: null,
      titles: [],
      glossaryItems: [],
      audio: null,
    };
  },
  methods: {
    toggleItem(index) {
      this.selectedItem = this.selectedItem === index ? null : index;
    },
    onWordClick(word, wordIndex, glossaryIndex) {
      console.log('Word clicked:', word);
      
      // Stop the currently playing audio (if any)
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      
      // Create a new audio object and play it
      this.audio = new Audio(`/VocabAudios/VocabAudiosLevel${glossaryIndex + 1}/${wordIndex  + 1}.mp3`);
      this.audio.play();
    },
    onSentenceClick(sentence, sentenceIndex, glossaryIndex) {
      console.log('Sentence clicked:', sentence);

      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }

      this.audio = new Audio(`/TranslateExercisisAudios/Level${glossaryIndex + 1}/${sentenceIndex + 1}.mp3`);
      this.audio.play();
    },
  },

  async mounted() {
    this.isLoading = true; // Start loading

    try {
      const response = await fetch('/LevelNames.txt');
      const data = await response.text();
      this.titles = data.split('\n').filter(title => title.trim().length > 0);

      for (let i = 0; i < this.titles.length; i++) {
        // Fetching vocabulary
        const vocabResponse = await fetch(`/VocabExercisesTxt/VocabExercise${i + 1}.txt`);
        const vocabData = await vocabResponse.text();
        const vocabulary = vocabData.split('\n').filter(word => word.trim().length > 0)
          .map(word => {
            const parts = word.split('%%');
            return { original: parts[0], translation: parts[1] };
          });

        // Fetching sentences and their translations
        const questionsResponse = await fetch(`/TranslateExercisesTxt/TranslateQuestionsLevel${i + 1}.txt`);
        const questionsData = await questionsResponse.text();
        const questions = questionsData.split('\n').filter(q => q.trim().length > 0)
          .map(q => q.split('*')[0].trim());  // Updated this line

        const answersResponse = await fetch(`/TranslateExercisesTxt/TranslateAnswersLevel${i + 1}.txt`);
        const answersData = await answersResponse.text();
        const answers = answersData.split('\n').filter(a => a.trim().length > 0);

        const sentences = questions.map((q, index) => ({
          original: q,  // Now this contains only the sentence part
          translation: answers[index] || 'No translation available',
        }));

        // Pushing fully constructed object into the glossaryItems array
        this.glossaryItems.push({
          vocabulary,
          sentences,
        });
      }
      this.isLoading = false; // End loading
  } catch (error) {
    console.error('Error:', error);
    this.isLoading = false; // Ensure loading ends in case of error
  }
  },
};
</script>
<style scoped>
.glossary-list {
  /* ... your existing styles ... */
  box-sizing: border-box;
  width: 80%;
  margin-top: 50px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #e6e6e6;
  line-height: 1.5;
  font-size: 20px;
}

.glossary-item {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  background-color: #3333334d;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.glossary-item:hover {
  background-color: #5da1c97e;
}
.word-button, .sentence-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #333;  /* Adjust as needed */
  color: white; /* Adjust as needed */
  border: none;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  /* Or any other value depending on your layout */
}
.loading-spinner {
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.word-button:hover, .sentence-button:hover {
  background-color: #555;  /* Adjust as needed */
}

.original {
  flex-basis: 49%;
  background-color: #5da1c97e;
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
}

.translation {
  flex-basis: 49%;
  padding: 5px;
  border-radius: 5px;
}
.glossary-item > div {
  /* To avoid the title stretching due to flex container */
  align-self: flex-start;
}

.expanded-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  width: 100%; /* This will make it take the full width */
}

.glossary-item .expanded-content {
  max-height: 2000px; /* Adjust this value based on your requirement */
  overflow-y: auto; /* This will make the expanded content scrollable */
}
.scrollable-content {
  max-height: 400px; /* You can adjust as per your requirement */
  overflow-y: auto;  /* Make this div scrollable */
  margin-bottom: 20px;
  
}
.vocabulary, .sentences {
  max-height: 400px; /* Adjust this value based on your requirement */
  overflow-y: auto; /* This will make the vocabulary and sentences sections scrollable */
  margin-bottom: 20px;
  display: flex; 
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center; /* Center text */
}

</style>
